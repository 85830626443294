// $primary: #a51616;
@import url('https://fonts.googleapis.com/css?family=Aref+Ruqaa:400,700');

$primary: #3dbeee; /* MAIN COLOR */
$secondary: #ea5761; /* SECONDARY COLOR */
$blk: #333; /* DARK GRAY, REASON WHY IT'S SET TO #333 IS SO YOU CAN DARKEN IT IF NEEDED USING darken($blk, 10%); FOR EXAMPLE. YOU COULD ALSO USE 2 VARIABLES INSTEAD */
$wht: #fafafa;
$border-radius: 0px; /* USE THIS TO SET THE BORDER RADIUS FOR BUTTONS */
$footerLinks: #fafafa;

.flash {
	display:none;
}
body {
	font-family: 'Aref Ruqaa', serif;
	font-size: 2em;
	letter-spacing: 1px;
}
.logo {
	@media(max-width: 767px){
		max-height: 70px;
	}
}
nav {
	z-index: 1000;
	background: $primary;
	border:none;
}
.navbar-toggle{
	margin-top: 16px;
}
.navbar-default {
	background: $primary;
	border:none;
}

.navbar .navbar-nav {
    > li > a {
		text-align: center;
		height: 100px;
		display: flex;
		align-items: center;
		color: $wht;

		@media (max-width: 767px) {
		    height: 22px;
		    display: inline-block;
		}

	    &:hover, &:focus {
	    	background: $wht;
	    	color: darken($blk, 50%);
	    }
	}
}

.navbar-right {
	margin-top: 0px;
}

.navbar-toggle {
    margin: 20px 15px 8px 0px;
    border: 1px solid transparent;
    border-radius: 4px;

	@media (max-width: 388px) {
		margin-top: 14px;
	}
}


/** LOGIN FORM **/

@mixin btn1 {
	text-align: center;
	margin: 0 auto;
	border: 1px solid $primary;
	border-radius: $border-radius;
	background: $primary;
	color: $wht;
	padding: 0.5em 2em;

	&:hover {
		background: lighten($primary,10%);
		color: lighten($wht, 10%);
		box-shadow: 0px 0px 3px lighten($blk,10%);
	}
}

@mixin btn2 {
	@include btn1;
	background: darken($wht, 5%);
	color: $primary;
	border: none;

	&:hover {
		background: $wht;
		color: $primary;
		border: none;
	}
}

.modal-dialog {
	max-width: 300px;
	text-align: center;
	margin: 6em auto;

	.close {display: none;}

	.modal-content {
		color: $primary;

		h2 {
			text-align:center;
		}
	}

	input {
		border: none;
		border-bottom: 1px solid darken($primary,20%);
		text-align: center;
	}

	button {
		background: transparent;
		color: $wht;
		display: block;
		border:none;
		padding: 1em 2em;
		margin: 0 auto;

		&:hover {
			background: transparent;
			color: #fff;
			box-shadow: none;
			text-shadow: 0px 0px 3px $blk;
			border:none;
		}
	}

	input[type="submit"] {
		@include btn1;
		display: block;
		width: 75%;
	}

}

.modal-header, .modal-footer {
	background: $primary;
	color: $wht;
}

input#username {
	margin-bottom: 20px;
}
// start sections
section {
	padding: 50px 0;
	position: relative;
}
.section-a {
	background: url('../img/bg1.jpg')  center/cover;
	padding: 0;
  position: relative;
	.container-fluid {
		padding: 0;
	}
	p{
		margin-top: 15%;
		font-size: 2em;
		// margin: 0 auto;
		padding: 5%;
		color: $wht;
		@media(max-width:767px){
			padding: 20px;
			margin-top: 0;
			font-size: 1em;
			text-align: center;
		}
	}

}

.section-b {
	background: url('../img/bg2.jpg') top left/cover;
	padding: 350px 0;
  position: relative;
	p{
		color: $wht;
		font-size: 2em;
		text-transform: uppercase;
	}
	@media (max-width:1024px){
		padding: 200px 0;
	}
	@media (max-width:767px){
		padding: 100px 0;
	}
}
.section-c, .section-d {
	background: #00d2ff; /* fallback for old browsers */
	background: -webkit-linear-gradient(to left, #00d2ff , #928DAB); /* Chrome 10-25, Safari 5.1-6 */
	background: linear-gradient(to left, #00d2ff , #928DAB); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
	font-size: 1.3em;
	line-height: 2em;
	color: $wht;
	@media(max-width:767px){
		font-size: 1em;
		line-height: 1.5em;
	}
}
.form{
	background:rgba(255, 255, 255, 0.5);
	padding: 50px 100px;
	@media (max-width: 767px){
		padding: 20px;
	}
}

.cta {
	background: $wht;
	padding: 15px 25px;
	line-height: 1em;
	font-size:.8em;
	&:hover {
		background: $blk;
		color: $wht;
		transition: all 0.7s ease 0s;
	}
}
// ends sections
/** END LOGIN FORM **/

footer {
	padding: 20px 0px 20px;
	background: darken($blk, 25%);
	color: $footerLinks;
	font-size: .8em;

	a {
		color: $footerLinks;

		&:hover {
			color: lighten($footerLinks, 10%);
		}
	}
}

@media (max-width: 1024) {
	input#username, input#password {
  		font-size: 16px;
	}
}
